import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';

const Wrapper = styled.div` 
   ${fontstack.default}
  ${type('body')}
  position: relative;
  color: rgb(var(--theme-white));
  padding: 7px 16px;
  display: inline-block;

  user-select: none;
  transition: all .2s ease;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(var(--theme-black));
    opacity: 0.2;
    z-index: 0;
  }
`

const PillWrapper = styled(Wrapper)` 
  border-radius: 100px;
`

const SquareWrapper = styled(Wrapper)` 
  border-radius: 2px;
`

const Inner = styled.div`
  position: relative;
  z-index: 1;
  transform: translate(0, 0%);
  transition: transform .2s ease;

  ${({animation}) => animation === 'showing' ? `
    transform: translate(0, -150%);
  ` : animation === 'hiding' ? ` 
    transform: translate(0, 0%);
  ` : null}
`

const Toast = styled.div` 
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, 100%);
  transition: transform .2s ease;

  ${({animation}) => animation === 'showing' ? `
    transform: translate(0, 0%);
  ` : animation === 'hiding' ? ` 
    transform: translate(0, 100%);
  ` : null}
`


const SwatchPill = ({children}) => {
  return (
    <PillWrapper>
      <Inner>
      {children}
      </Inner>
    </PillWrapper>
  )
}

export { SwatchPill };


const SwatchSquare = ({children, show, ...rest}) => {
  const [visible, setVisible] = useState(show)
  const [animation, setAnimation] = React.useState("")

  useEffect(() => {
    if (show) {
      setVisible(true)
    }
    const timeout = setTimeout(() => {
      setVisible(show)
    }, 200)
    setAnimation(show ? 'showing' : 'hiding')

    return () => clearTimeout(timeout)
  }, [show])

  return (
    <SquareWrapper {...rest}>
      <Inner animation={animation}>
      {children}
      </Inner>
      <Toast animation={animation}>Hex Copied</Toast>
    </SquareWrapper>
  )
}

export { SwatchSquare };