import React from 'react';
import styled from 'styled-components';
import AlbumSection, { AlbumSectionLabel } from './AlbumSection';
import CategoryTag from 'components/CategoryTag/CategoryTag';
import { media } from "utils/mediaQuery";


const CategoryTagMod = styled(CategoryTag)`
  &:not(:last-of-type) {
      margin: 0 0 24px;
    }
  
  ${media.small` 
    &:not(:last-of-type) {
      margin: 0 16px 0 0;
    }
  `}

`

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${media.small` 
    flex-direction: row;
    align-items: flex-start;
  `}
`

const AlbumCategory = ({types,formats}) => {
  return (
    <AlbumSection>
      <div><AlbumSectionLabel>Category</AlbumSectionLabel></div>
      <Tags>
        {types.map((object,i) => {
          if(object.type) {
            const type = object.type.document;
            return type ? <CategoryTagMod key={i} to={`/list?type=${type.uid}`}>{type.data.label}</CategoryTagMod> : null;
          }
          
        })}
        {formats.map((object,i) => {
          if(object.format) {
            const format = object.format.document;
            return format ? <CategoryTagMod key={i} to={`/list?format=${format.uid}`}>{format.data.label}</CategoryTagMod> : null;
          }
        })}
      </Tags>
    </AlbumSection>
  )
}

export default AlbumCategory;