import React from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { SwatchSquare } from 'components/SwatchTag/SwatchTag';

const Wrapper = styled.button`
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

const HexCopy = ({hex, copyFinished}) => {
  const [ show, setShow ] = React.useState(false);

  const toggle = () => {
    copy(hex);
    setShow(true)
    setTimeout(()=>{
      setShow(false);

      if(copyFinished) {
        copyFinished();
      }
    }, 2000);
    
  }

  return (
    <Wrapper onClick={toggle} >
      <SwatchSquare show={show}>Click to Copy</SwatchSquare>
    </Wrapper>
  )
}

export default HexCopy;