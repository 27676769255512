import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Swatch from './Swatch';
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`

`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SwatchWrap = styled.div`
  width: calc(100% / 3);

  &:nth-child(3n+2){
    display: flex;
    justify-content: center;
  }

  :nth-child(3n+3){
    display: flex;
    justify-content: flex-end;
  }

  &:nth-child(3n+1):nth-last-child(-n + 3), 
  &:nth-child(3n+1):nth-last-child(-n + 3) ~ &{
    margin: 58px 0 0;
  }

  ${media.small` 
    width: auto;

    &:nth-child(3n+1):nth-last-child(-n + 3), 
    &:nth-child(3n+1):nth-last-child(-n + 3) ~ &{
      margin: 0;
    }
  `}
`

const Palette = ({data}) => {
  const [ isPaused, setIsPaused ] = useState(false)
  return (
    <Wrapper>
      <Row>
        { data && data.dominant_colors ? Object.keys(data.dominant_colors).map((key,index)=> {

          return <SwatchWrap key={index}>
            <Swatch hexVal={data.dominant_colors[key].hex} isPaused={isPaused} setIsPaused={setIsPaused}/>
          </SwatchWrap>
        }) : null }
      </Row>
    </Wrapper>
  )
}

export default Palette;
