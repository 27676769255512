import React from 'react';
import styled from 'styled-components';
import { media } from 'utils/mediaQuery';
import { 
  ArrowIcon,
  PlayIcon,
  PlayScalableIcon,
  PauseIcon,
  PauseScalableIcon,
  FullscreenIcon,
  SpotifyIcon,
  AppleMusicIcon,
  BandcampIcon,
  YoutubeIcon
} from './assets';


const Base = styled.div` 
//component vars
  --default-background: rgb(var(--theme-white));
  --default-fill: rgb(var(--theme-black));
  --hover-background: rgb(var(--theme-black));
  --hover-fill: rgb(var(--theme-white));

  --default-secondary: rgb(var(--theme-black));
  --hover-secondary:  rgb(var(--theme-black));

  background: var(--default-background);
  //transition: background .2s ease;

  & > svg {
    fill: var(--default-fill);
    //transition: fill .2s ease;
    path[class$="icon-secondary"] {
      fill: var(--default-secondary);
    }
  }

  ${media.small` 
    &:hover {
      background: var(--hover-background);

      & > svg {
        fill: var(--hover-fill);

        path[class$="icon-secondary"] {
          fill: var(--hover-secondary);
        }
      }
    }
  `}

  ${({active}) => active && `
    background: var(--hover-background);

    & > svg {
      fill: var(--hover-fill);

      path[class$="icon-secondary"] {
        fill: var(--hover-secondary);
      }
    }
  `}
`

const CircleDefault = styled(Base)`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;  
`

const CircleMedium = styled(CircleDefault)` 
  width: 40px;
  height: 40px;

  --default-background: rgba(var(--theme-white),0.5);
  --hover-background:  rgba(var(--theme-black),0.5);
  backdrop-filter: blur(5px);
`

const CircleLarge = styled(CircleDefault)` 
  width: 60px;
  height: 60px;

  & > svg {
    width: 30%;
  }
  --default-background: rgba(var(--theme-white),0.5);
  --hover-background:  rgba(var(--theme-black),0.5);
`

const CirclePauseLarge = styled(CircleDefault)`
  width: 60px;
  height: 60px;

  & > svg {
    width: 25%;
  }

  --default-background: rgba(var(--theme-white),0.5);
  --hover-background:  rgba(var(--theme-black),0.5);
`

const CircleSpotify = styled(CircleDefault)`
  --default-background: #1ED760;
  --default-fill: rgb(var(--theme-white));
  --hover-background: #16A147;
  --hover-fill: rgb(var(--theme-white));
`

const CircleAppleMusic = styled(CircleDefault)`
  --default-background: #FA586A;
  --default-fill: rgb(var(--theme-white));
  --hover-background: #C94756;
  --hover-fill: rgb(var(--theme-white));
`

const CircleBandcamp = styled(CircleDefault)`
  --default-background: #1DA0C3;
  --default-fill: rgb(var(--theme-white));
  --hover-background: #167E99;
  --hover-fill: rgb(var(--theme-white));
`

const CircleYoutube = styled(CircleDefault)`
  --default-background:#FF0302;
  --default-fill: rgb(var(--theme-white));
  --hover-background:#C60100;
  --hover-fill: rgb(var(--theme-white));
  --default-secondary:#FF0302;
  --hover-secondary: #C60100;
`

const IconSetWrapper = ({children, active}) => {

  const icon = React.Children.map(children, (child, index) => {
    if(React.isValidElement(child)) {
      return React.cloneElement(child,{ active: active });
    }
    return child;
  });

  return icon
}

export const ArrowRight = (props) => {
  return <IconSetWrapper {...props}>
      <CircleMedium>
        <ArrowIcon/>
      </CircleMedium>
    </IconSetWrapper>
}

export const ArrowLeft = (props) => {
  return <IconSetWrapper {...props}>
      <CircleMedium>
        <ArrowIcon style={{transform: "rotate(180deg)"}}/>
      </CircleMedium>
    </IconSetWrapper>
}

export const Play = (props) => {
  return <IconSetWrapper {...props}>
      <CircleDefault>
        <PlayIcon/>
      </CircleDefault>
    </IconSetWrapper>
}

export const PlayLarge = (props) => {
  return <IconSetWrapper {...props}>
      <CircleLarge>
        <PlayScalableIcon/>
      </CircleLarge>
    </IconSetWrapper>
}

export const Pause = (props) => {
  return <IconSetWrapper {...props}>
    <CircleDefault>
      <PauseIcon/>
    </CircleDefault>
    </IconSetWrapper>
}

export const PauseLarge = (props) => {
  return <IconSetWrapper {...props}>
      <CirclePauseLarge>
        <PauseScalableIcon/>
      </CirclePauseLarge>
    </IconSetWrapper>
}


export const Fullscreen = (props) => {
  return <IconSetWrapper {...props}>
    <CircleDefault>
      <FullscreenIcon/>
    </CircleDefault>
    </IconSetWrapper>
}

export const Spotify = (props) => {
  return (
    <IconSetWrapper {...props}>
      <CircleSpotify>
        <SpotifyIcon/>
      </CircleSpotify>
    </IconSetWrapper>
  )
}

const CustomAppleMusicIcon = styled(AppleMusicIcon)`
  width: 12px;
  height: 12px;
`

export const AppleMusic = (props) => {
  return (
    <IconSetWrapper {...props}>
      <CircleAppleMusic>
        <CustomAppleMusicIcon/>
      </CircleAppleMusic>
    </IconSetWrapper>
  )
}

export const Bandcamp = (props) => {
  return (
    <IconSetWrapper {...props}>
      <CircleBandcamp>
        <BandcampIcon/>
      </CircleBandcamp>
    </IconSetWrapper>
  )
}

export const Youtube = (props) => {
  return (
    <IconSetWrapper {...props}>
      <CircleYoutube>
        <YoutubeIcon/>
      </CircleYoutube>
    </IconSetWrapper>
  )
}