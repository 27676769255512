import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AlbumSection, { AlbumSectionLabel } from './AlbumSection';
import Palette from 'modules/Palette/Palette';

const AlbumColour = ({image}) => {
  const [ paletteData, setPaletteData ] = useState();
  useEffect(() => {
    if(image) {
      fetch(`${image}&palette=json`)
      .then(response => response.json())
      .then(data => {
        setPaletteData(data);
      });
    }
  },[])

  return (
    <AlbumSection>
      <div><AlbumSectionLabel>Colour</AlbumSectionLabel></div>
      <div>
        <Palette data={paletteData}/>
      </div>
    </AlbumSection>
  )
}

export default AlbumColour;