import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { OutlineTag } from 'components/PillTag/PillTag';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';
import { media } from "utils/mediaQuery";

const Wrapper = styled.li`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.medium` 
    flex-direction: row;
    justify-content: space-between;
  `}

  &:not(:last-child) {
    margin: 0 0 24px;
  }
`

const Credit = styled.div`
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-grey));
`

const OutlineTagMod = styled(OutlineTag)`
  margin: 0 -10px 0 0;
  ${media.medium` 
    margin: 0;
  `}
`



const CreditLine = ({name, url, credit}) => {
  const Tag = <OutlineTagMod>{name}{url ? ' ↗' : ''}</OutlineTagMod>;
  return (
    <Wrapper>
      { url ? <OutboundLink href={url} target="_blank">{Tag}</OutboundLink> : Tag}
      <Credit>{credit}</Credit>
    </Wrapper>
  )
}

export default CreditLine;