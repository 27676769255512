import React from 'react';
import styled from 'styled-components';
import AlbumSection, { AlbumSectionLabel } from './AlbumSection';
import CreditLine from 'components/CreditLine/CreditLine';


const CreditLines = styled.ul`
  list-style: none;
  margin: 0 0 0 -10px;
  padding: 0;
`

const AlbumCredits = ({data}) => {
  return (
    <AlbumSection>
      <div><AlbumSectionLabel>Credits</AlbumSectionLabel></div>
      <div>
        <CreditLines>
          {data && data.map((credit,i)=><CreditLine key={i} name={credit.name} url={credit.link.url} credit={credit.role} />)}
        </CreditLines>
      </div>
    </AlbumSection>
  )
}

export default AlbumCredits;