import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import rgbHex from 'rgb-hex';
import { SwatchPill } from 'components/SwatchTag/SwatchTag';
import { gsap } from 'gsap';
import copy from 'copy-to-clipboard';
import HexCopy from 'components/HexCopy/HexCopy';
import XIcon from "./assets/x.svg";
import { distanceOfLine } from 'utils/distanceOfLine';
import { findPointBetweenTwo } from 'utils/findPointBetweenTwo';
import { normalize } from 'utils/normalize';
import { media } from "utils/mediaQuery";


const Wrapper = styled.div` 
  position: relative;
`

const Circle = styled.div`
  position: relative;
  border-radius: 50%;
  transform-origin: center center;
  z-index: 0;
  width: 20px; 
  height: 20px;
`

const InnerCircle =  styled.div`
  display: block;
  border-radius: 50%;
  width: 2000px;
  height: 2000px;
  left: -990px;
  top: -990px;
  transform-origin: center;
  background: ${props => props.hex ? `${props.hex}` : props.rgb ? `rgb(${props.rgb})` : 'none'};
  
  position: relative;

  ${media.small`
    width: 5000px;
    height: 5000px;
    left: -2490px;
    top: -2490px;
  `}
`

const OuterCircle = styled.div`
  position: relative;
  transition: transform 1s ease;
`

const MobileClickCircle = styled.div` 
  position: absolute;
  width: 60px;
  height: 60px;
  left: -20px;
  top: -20px;
  z-index: 1;

  ${media.small`
    display: none;
  `}
`

const CloseBar = styled.div`
  position: absolute;
  width: 100%;
  display: flex; 
  justify-content: center;
  bottom: 60px;
  left: 0;

  ${media.small`
    display: none;
  `}
`

const MobileClose = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(var(--theme-black));
    opacity: 0.2;
    z-index: 0;
  }

`

const Screen = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.active ? '11' : '-1'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .2s ease;
  pointer-events: ${props => props.active ? 'auto' : 'none'};
  cursor: ${props => props.active ? 'pointer' : 'default'};
`

const ClickArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
`

const Swatch = ({rgb, hexVal, isPaused, setIsPaused}) => {
  const swatchRef = useRef();
  const swatchInnerRef = useRef();
  const mainRef = useRef();
  const moveEnabled = useRef(true);
  const closePause = useRef(true);
  const rgbString = rgb ? rgb.toString() : null;
  const [ isActive, setIsActive ] = useState(false);
  const hex = hexVal ? hexVal : rgb ? `#${rgbHex(rgb[0], rgb[1], rgb[2]).toUpperCase()}` : '#000';

  const scaleValue = useRef(window.matchMedia("(min-width: 768px)").matches ? 0.004 : 0.01);

  useEffect(() => {
    
    window.addEventListener('mousemove', onMouseMove);
    document.querySelector("body").addEventListener("mouseleave", onMouseLeave);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      document.querySelector("body").removeEventListener("mouseleave", onMouseLeave);
    }
  }, [])

  const onMouseLeave = () => {
    setTimeout(()=>{
      mainRef.current.style.transform = `translate(0px, 0px)`;
      gsap.to(swatchInnerRef.current,{ scale: scaleValue.current, duration: 0.2 })
    }, 200)
  }
  
  const onMouseMove = (e) => {
    if(window.matchMedia("(min-width: 768px)").matches) {
      if(swatchRef.current && moveEnabled.current) {
        const swatchPosition = swatchRef.current.getBoundingClientRect();
        
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const swatchX = (swatchPosition.left + swatchPosition.right) / 2;
        const swatchY = (swatchPosition.top + swatchPosition.bottom) / 2;
        const distance = distanceOfLine(mouseX,mouseY,swatchX,swatchY);
        
        if(distance < 500) {
          const normal = normalize(distance, 0, 500);
          /*const point = findPointBetweenTwo(.8,mouseX,mouseY,swatchX,swatchY);
          const relativeX = point.x - swatchX;
          const relativeY = point.y - swatchY;*/
          swatchInnerRef.current.style.transform = `scale(${scaleValue.current + normal/200})`;
          // mainRef.current.style.transform = `translate(${relativeX}px, ${relativeY}px)`;
        } else {
          mainRef.current.style.transform = `translate(0px, 0px)`;
        }
  
      } else if(swatchRef.current && !moveEnabled.current && !closePause.current) {
        const swatchPosition = swatchRef.current.getBoundingClientRect();
        
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const swatchX = (swatchPosition.left + swatchPosition.right) / 2;
        const swatchY = (swatchPosition.top + swatchPosition.bottom) / 2;
        const distance = distanceOfLine(mouseX,mouseY,swatchX,swatchY);
        if(distance > 150) {
         closeCircle();
        }
      }
    }
  }

  const onMouseEnter = () => {
    openScreen();
  }

  const onMobileClick = () => {
    if(!window.matchMedia("(min-width: 768px)").matches) {
      openScreen();
    }
  }

  const openScreen = () => {
    if(!isPaused) {
      setIsPaused(true);
      moveEnabled.current = false;
      closePause.current = false;
      mainRef.current.style.zIndex = 10;
      gsap.to(swatchInnerRef.current,{ scale: 1, duration: 0.4 })
      setIsActive(true);
    }
  }

  const onClick = () => {
    if(window.matchMedia("(min-width: 768px)").matches) {
      copy(hex);
      closeCircle();
    }
  }


  const closeCircle = () => {
    setIsActive(false);
    swatchInnerRef.current.style.transform = `scale(${scaleValue.current})`;
    gsap.to(swatchInnerRef.current,{ scale: scaleValue.current, duration: 0.2 })
    closePause.current = true;
    setTimeout(() => {
      mainRef.current.style.zIndex = 0;
      moveEnabled.current = true;
      closePause.current = false;
      setIsPaused(false);
    }, 200)
  }

  return (
    <Wrapper>
      <OuterCircle ref={mainRef}>
        <MobileClickCircle onClick={onMobileClick}/>
        <Circle rgb={rgbString} hex={hex} ref={swatchRef}  onMouseEnter={onMouseEnter}>
          <InnerCircle rgb={rgbString} hex={hex} ref={swatchInnerRef} style={{transform: `scale(${scaleValue.current})`}} />
        </Circle>
      </OuterCircle>
      <Screen active={isActive}>
        <HexCopy hex={`${hex}`} copyFinished={closeCircle}/>
        <SwatchPill>{`${hex}`}</SwatchPill>
        <CloseBar>
          <MobileClose onClick={closeCircle}>
            <XIcon />
          </MobileClose>
        </CloseBar>
        <ClickArea onClick={onClick}/>
      </Screen>
    </Wrapper>
  )

}

export default Swatch;