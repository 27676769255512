import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Play, Pause, Spotify, AppleMusic, Bandcamp, Youtube, Fullscreen } from 'components/IconSet/IconSet';
import CategoryTag from 'components/CategoryTag/CategoryTag';
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${media.medium` 
    width: auto;
    justify-content: space-between;
  `}

`

const IconWrap = styled(OutboundLink)`
  display: block;
  &:not(:last-child) {
    margin: 0 16px 0 0;
  }
`

const IconWrapNoAnchor = styled(IconWrap).attrs({
  as: "div"
})`
  cursor: pointer;
`

const FullscreenWrapper = styled(IconWrapNoAnchor)`
  display: none;
  ${media.medium` 
    display: block;
  `}
`

const IconMainWrapper = styled.div` 
  display: flex;
`



const VideoPlayer = ({videoObject, media_links}) => {

  const onFullScreen = () => {
    
    if(videoObject) {
      const requestFullScreen = videoObject.requestFullscreen || videoObject.webkitRequestFullscreen || videoObject.mozRequestFullScreen ||  videoObject.msRequestFullscreen;
      requestFullScreen.call(videoObject);

      typeof window !== "undefined" && typeof window.gtag !== 'undefined' && window.gtag("event", "fullscreen", {})
    }
  }

  return (
    <Wrapper>
      <IconMainWrapper>
        <FullscreenWrapper onClick={onFullScreen}>
          <Fullscreen/>
        </FullscreenWrapper>
        { media_links && media_links.map((link)=>{

          if(link.source === 'spotify') {
            return (
              <IconWrap href={link.link.url} target="__blank">
                <Spotify />
              </IconWrap>
            )
          }

          if(link.source === 'apple') {
            return (
              <IconWrap href={link.link.url} target="__blank">
                <AppleMusic />
              </IconWrap>
            )
          }

          if(link.source === 'bandcamp') {
            return (
              <IconWrap href={link.link.url} target="__blank">
                <Bandcamp />
              </IconWrap>
            )
          }

          if(link.source === 'youtube') {
            return (
              <IconWrap href={link.link.url} target="__blank">
                <Youtube />
              </IconWrap>
            )
          }
          
        }) }
      </IconMainWrapper>
    </Wrapper>
  )
}

export default VideoPlayer; 