import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from "gatsby";
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';


const activeState = css`
  background: rgb(var(--theme-black));
  color: rgb(var(--theme-white));
`

const Wrapper = styled(Link)` 
   ${fontstack.default}
  ${type('body')}
  position: relative;
  background: rgb(var(--theme-white));
  color: rgb(var(--theme-black));
  padding: 3px 10px;
  display: inline-block;
  border-radius: 2px;
  user-select: none;
  //transition: all .2s ease;

  &:not(:last-of-type) {
    margin: 0 16px 0 0;
  }

  ${({disableHover}) => !disableHover && `
    &:hover {
      ${activeState}
    }
  `}
  

  ${({active}) => active && `
    ${activeState}
  `}
`

const CategoryTag = ({children, active, disableHover, ...rest}) => {

  return (
    <Wrapper active={active} disableHover={disableHover} {...rest}> 
      { children }
    </Wrapper>
  )
}

export default CategoryTag;