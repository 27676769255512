import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Play, Pause, Spotify, AppleMusic, Bandcamp } from 'components/IconSet/IconSet';
import CategoryTag from 'components/CategoryTag/CategoryTag';
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${media.medium` 
    width: auto;
  `}

`

const SongTitle = styled(CategoryTag)`
  margin: 0 16px 0 0;
  display: block;

  ${media.medium` 
    display: none;
  `}

  ${media.largeplus` 
    display: block;
  `}
` 

const AudioWrap = styled.div`
  display: flex;
`

const PlayWrap = styled.button`
  margin: 0 8px 0 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
`

const IconWrap = styled(OutboundLink)`
  display: block;
  &:not(:last-child) {
    margin: 0 16px 0 0;
  }
`

const IconMainWrapper = styled.div` 
  display: flex;
`

const AudioPlayer = ({src,title, media_links}) => {
  const [ isPlay, setIsPlay ] = useState(false);
  const AudioRef = useRef();

  const playToggle = () => {
    const audio = AudioRef.current;
    if(isPlay) {
      audio.pause();
      setIsPlay(false);
    } else {
      audio.play();
      setIsPlay(true);
      typeof window !== "undefined" && typeof window.gtag !== 'undefined' && window.gtag("event", "play", {
        "media_type": "audio",
        "content_name": title
      })
    }
  }


  return (
    <Wrapper>
      <AudioWrap>
        <audio ref={AudioRef} src={src} preload='metadata' loop />
        <PlayWrap onClick={playToggle} >{ isPlay ? <Pause /> : <Play /> }</PlayWrap>
        <SongTitle disableHover>{title}</SongTitle>
      </AudioWrap>
      <IconMainWrapper>
        { media_links && media_links.map((link,i)=>{

          if(link.source === 'spotify') {
            return (
              <IconWrap key={i} href={link.link.url} target="__blank">
                <Spotify />
              </IconWrap>
            )
          }

          if(link.source === 'apple') {
            return (
              <IconWrap key={i} href={link.link.url} target="__blank">
                <AppleMusic />
              </IconWrap>
            )
          }

          if(link.source === 'bandcamp') {
            return (
              <IconWrap key={i} href={link.link.url} target="__blank">
                <Bandcamp />
              </IconWrap>
            )
          }
          
        }) }
      </IconMainWrapper>
    </Wrapper>
  )
}

export default AudioPlayer; 