import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { getImage } from "gatsby-plugin-image";
import { FloppyStage, FloppyAlbum } from '@manualengineering/floppy';
import { SiteContext } from 'context/site-context';
import SimpleCarousel, { Image } from './SimpleCarousel';

const CanvasWrapper = styled.div`
  position: absolute;
  width: calc(100% + 30%);
  height: calc(100% + 30%);
  top: -15%;
  left: -15%;
`


const SimpleCarouselThreeDee = ({children, texture, textureImageData, alt, image, ...rest}) => {
  const { width } = React.useContext(SiteContext);
  const floppy = useRef();
  const example = useRef();

  useEffect(() => {
    return () => {
      if(example.current && floppy.current) {
        example.current.destroy();
        example.current = null;
        floppy.current = null;
      }
    }
  }, [width]);

  const containerEl = useCallback(node => {
    if (node !== null && !floppy.current && !example.current) {
        console.log('load album');
        floppy.current = new FloppyAlbum(texture,1.24, textureImageData.width, textureImageData.height, textureImageData.placeholder.fallback);
        example.current = new FloppyStage(node, floppy.current,{
          requireCallback: true,
          ground: false,
          background: false,
          trailEffect: false,
          elastic: true,
          stats: false,
          animation: 'rotate'
        });
        example.current.startRender();
    }
}, [width]);

  return (
    <>
    { width && 
      <SimpleCarousel {...rest}>
      { width >= 900 ? <CanvasWrapper ref={containerEl}></CanvasWrapper> : null}
      { children }
      </SimpleCarousel>
    }
    </>
  )
}

export default SimpleCarouselThreeDee;