import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import AlbumSection, { AlbumSectionLabel } from './AlbumSection';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import { getImage } from "gatsby-plugin-image";
import test6 from 'images/Gilligan_1.jpg';
import test7 from 'images/jpegmafia_1_Thumb.jpg';
import test8 from 'images/Moses_1.jpg';
import test9 from 'images/Wavves_1.jpg';
import { media } from "utils/mediaQuery";


const NextSection = styled(AlbumSection)` 
  grid-template-columns: repeat(3, 1fr);  
  ${media.medium` 
    grid-template-columns: repeat(2, 1fr);  
  `}  
  margin-bottom: 84px;
`

const NextGrid = styled.div`
  grid-column: 2 / span 2;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  column-gap: 16px;
  row-gap: 40px;

  ${media.small` 
    grid-template-columns: repeat(2,1fr);
  `}  

  ${media.medium` 
    grid-column: 2 / span 1;
  `}  
`

const AlbumNext = ({data}) => {
  return (
    <NextSection>
      <div><AlbumSectionLabel>Next</AlbumSectionLabel></div>
      <NextGrid>
        {data ? data.map((object,i) => {
           const slug = object.node.uid;
           const data = object.node.data;
           const {
             artist,
             project,
             carousel,
             video_thumb,
           } = data;

           const firstImage = carousel.length > 0 ? carousel[0] : null;
           const image = getImage(firstImage.image)
           const video_image = getImage(video_thumb);

         return <Link to={`/${slug}`} key={i}>
            <Thumbnail 
            image={image || video_image} 
            alt={image ? firstImage.image.alt : null}
            artist={artist}
            project={project}
            isNext />
          </Link>
        }): 
        <>
        <Thumbnail 
          image={test6} 
          artist='Vincent Staples' 
          project='Law of Averages'
          isNext/>
        <Thumbnail 
          image={test7} 
          artist='Vincent Staples' 
          project='Law of Averages'
          isNext/>
        <Thumbnail 
          image={test8} 
          artist='Vincent Staples' 
          project='Law of Averages'
          isNext/>
        <Thumbnail 
          image={test9} 
          artist='Vincent Staples' 
          project='Law of Averages'
          isNext/>
        </>}
        
      </NextGrid>
    </NextSection>
  )
}

export default AlbumNext;