import React from 'react';
import styled from 'styled-components';
import Section from 'components/Section/Section';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';


const AlbumSectionWrapper = styled(Section)`
  margin: 120px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
`

const AlbumSection = ({children, ...rest}) => {
  return (
    <AlbumSectionWrapper {...rest}>
      {children}
    </AlbumSectionWrapper>
  )
}

export default AlbumSection;


const Label = styled.h2`
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-primary));
  margin: 0;
`

const AlbumSectionLabel = ({children}) => {
  return <Label>{children}</Label>
}

export { AlbumSectionLabel }