import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import { SiteContext } from 'context/site-context';
import { ArrowRight, ArrowLeft } from 'components/IconSet/IconSet';
import { getContainedSize } from 'utils/getContainedSize';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { media } from 'utils/mediaQuery';
import "swiper/css";
import "./assets/swiper-override.css";
import { node } from 'prop-types';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

const WrapperInner = styled.div`
  position: relative;
  height: 100%;

  ${media.medium` 
    position: absolute;
    width: 100%;
    height: calc(100% + 30%);
    top: -15%;
  `}
`

const ControlsWrapper = styled.div` 
  position: relative;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;

  ${media.medium` 
    display: flex;
  `}
`

const ControlsArrow = styled.div`
  pointer-events: auto;
  padding: 20px;
  opacity: ${(props) => props.show ? 1 : 0};
`

const SimpleCarousel = ({children, titlePosCallback, ...rest}) => {
  const { width } = React.useContext(SiteContext);
  const [ slides, setSlides ] = useState();
  const [ hasPrev, setHasPrev ] = useState(false);
  const [ hasNext, setHasNext ] = useState(false);
  const [ prevActive, setPrevActive ] = useState(false);
  const [ nextActive, setNextActive ] = useState(false);
  const SwiperRef = useRef();
  const WrapperRef = useRef();
  const ActiveIndex = useRef();
  const TitlePositions = useRef([]);


  useEffect(()=>{
    const containerHeight = WrapperRef.current.offsetHeight;
    const containerWidth = WrapperRef.current.offsetWidth;
    const theSlides = React.Children.map(children, (child,index) => {
      if(React.isValidElement(child)) {
        const style = width >= 900 ? {width: `${containerHeight}px`, height: `${containerHeight}px`} : {};
        return <SwiperSlide style={style}>{React.cloneElement(child, { getRealImageSize: getRealImageSize, index: index - 1, containerWidth: containerWidth, containerHeight: containerHeight })}</SwiperSlide>;
      }
      return child;
    });

    setSlides(theSlides);
    return () => {
      
    }
  },[children]);

  const getRealImageSize = (index,size) => {
    TitlePositions.current[index] = size[1];

    if(ActiveIndex.current === index) {
      titlePosCallback(TitlePositions.current[index]);
    }
  }

  const hasPrevOrNext = (index) => {
    
    if(slides.length > 1) {
      if(index === 0) {
        setHasPrev(false)
      } else {
        setHasPrev(true);
      }
  
      if(index === slides.length - 1) {
        setHasNext(false);
      } else {
        setHasNext(true);
      }
    }
    
  }

  return (
    <Wrapper ref={WrapperRef} {...rest}>
      <ControlsWrapper>
        <ControlsArrow 
          className="prev-button" 
          show={hasPrev} 
          onMouseEnter={()=>setPrevActive(true)} 
          onMouseLeave={()=>setPrevActive(false)}>
          <ArrowLeft active={prevActive}/>
        </ControlsArrow>
        <ControlsArrow 
          className="next-button" 
          show={hasNext}
          onMouseEnter={()=>setNextActive(true)} 
          onMouseLeave={()=>setNextActive(false)}>
          <ArrowRight active={nextActive}/>
        </ControlsArrow>
      </ControlsWrapper>
      <WrapperInner>
        {slides ? 
        <Swiper
        ref={SwiperRef}
        slidesPerView={'auto'}
        spaceBetween={width >= 900 ? 250 : 0}
        centeredSlides={true}
        touchEventsTarget="container"
        touchStartPreventDefault={false}
        updateOnImagesReady={true}
        modules={[Navigation]}
        navigation={{
          prevEl: ".prev-button",
          nextEl: ".next-button"
        }}
        onImagesReady={(e) =>{
          ActiveIndex.current = e.snapIndex;
          const pos = TitlePositions.current[ActiveIndex.current];

          hasPrevOrNext(ActiveIndex.current);
          if(titlePosCallback && pos) {
            titlePosCallback(pos);
          }
        }}
        onSlideChange={(e) => {
          ActiveIndex.current = e.snapIndex;
          const pos = TitlePositions.current[ActiveIndex.current];
          hasPrevOrNext(ActiveIndex.current);
          if(titlePosCallback && pos) {
            titlePosCallback(pos);
          }
        }}
        style={{height:'100%'}}>
            { slides }
        </Swiper> : null 
        }
      </WrapperInner>
    </Wrapper>
    
  )
}

export default SimpleCarousel;


const ImageElement = styled.img` 
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  user-select: none;
  margin: 0;

  ${media.medium` 
    object-position: center;
  `}
`

const ImageGatsby = styled(GatsbyImage)` 
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
  margin: 0;
  object-fit: contain;

  & img {
    object-position: bottom;
  }

  ${media.medium` 
    & img {
    object-position: center;
  }
  `}
`

const Image = ({getRealImageSize, containerWidth, containerHeight, index, src, image, ...rest}) => {
  const imageRef = useCallback(node => {
    if (node !== null) {
      node.addEventListener('load', (e) => {
        getRealImageSize(index, getContainedSize(e.target))
      })
    }
  },[]);


  const onGatsbyImageLoad = () => {
    const width = image.width;
    const height = image.height;
    const ratio = width / height;

    let newWidth = containerHeight * ratio;
    let newHeight = containerHeight;
    if (newWidth > containerWidth) {
      newWidth = containerWidth;
      newHeight = containerWidth / ratio
    } 

    getRealImageSize(index, [newWidth, newHeight])
  }


  return src ? <ImageElement ref={imageRef} src={src} {...rest}/> : image ? <ImageGatsby objectFit={"contain"} onLoad={onGatsbyImageLoad} loading='eager' image={image} {...rest}/> : null;
}

export { Image }