import React from 'react';
import styled from 'styled-components';
import AlbumSection, { AlbumSectionLabel } from './AlbumSection';
import AlbumTitle from 'components/AlbumTitle/AlbumTitle';
import AudioPlayer from 'modules/AudioPlayer/AudioPlayer';
import VideoPlayer from 'modules/VideoPlayer/VideoPlayer';
import { media } from "utils/mediaQuery";

const AlbumTopLineSection = styled(AlbumSection)`
  margin: 0;
  grid-template-columns: repeat(1, 1fr);

  ${media.medium` 
    grid-template-columns: repeat(2, 1fr);
  `}

`

const MobileAlbumTopLineSection = styled(AlbumSection)` 
  margin: 0 0 16px;
  position: ${props => props.isVideo ? 'relative' : 'absolute'};
  width: 100%;
  bottom: ${props => props.pos ? `${props.pos}px` : '0'};
  transition: bottom .2s ease;

  ${media.medium` 
    display: none;
  `}
`

const ArtistWrapper = styled.div`
  display: none;
  ${media.medium` 
    display: block;
  `}
`

const AlbumTitleMod = styled(AlbumTitle)` 
  display: none;
  ${media.medium` 
    display: block;
  `}
`

const MobileAlbumTitle = styled(AlbumTitle)` 
  display: flex;
  justify-content: flex-end;
  align-items:flex-end;
`

const MainWrapper = styled.div` 
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const AlbumTopLine = ({artist, title, year, audio, videoObject, media_links}) => {
  return (
    <AlbumTopLineSection>
      <ArtistWrapper><AlbumSectionLabel>{artist}</AlbumSectionLabel></ArtistWrapper>
      <MainWrapper>
        <AlbumTitleMod year={year} title={title} />
        { audio ? 
          <AudioPlayer src={audio.src} title={audio.title} media_links={media_links}/> : 
          videoObject ?
          <VideoPlayer videoObject={videoObject} media_links={media_links} /> : null }
      </MainWrapper>
    </AlbumTopLineSection>
  )
}

export default AlbumTopLine;

const MobileAlbumTopLine = ({artist, title, year, pos, isVideo}) => {
  return (
    <MobileAlbumTopLineSection pos={pos} isVideo={isVideo}>
      <AlbumSectionLabel>{artist}</AlbumSectionLabel>
      <MobileAlbumTitle year={year} title={title} />
    </MobileAlbumTopLineSection>
  )
}

export { MobileAlbumTopLine };