import React from 'react';
import styled from 'styled-components';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';

const Wrapper = styled.div`
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-primary));
`

const Year = styled.sup` 
  ${type('metadata')}
  color: rgb(var(--theme-grey));
  margin: 0 0 0 4px;
`

const AlbumTitle = ({title,year, ...rest}) => {

  return (
    <Wrapper {...rest}>{title}<Year>{year}</Year></Wrapper>
  )
}

export default AlbumTitle;
