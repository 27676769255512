import React, { useEffect, useState, useRef } from "react"
import queryString from 'query-string';
import styled from 'styled-components';
import { graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image";
import { SiteContext } from 'context/site-context';
import Section from "components/Section/Section";
import Layout from "components/Layout/Layout"
import Seo from "components/seo"
import SimpleCarousel, { Image as CarouselImage } from "components/SimpleCarousel/SimpleCarousel";
import SimpleCarouselThreeDee from "components/SimpleCarousel/SimpleCarouselThreeDee";
import Megatron from "modules/Megatron/Megatron";
import AlbumTopLine, { MobileAlbumTopLine } from "modules/AlbumDetailPage/AlbumTopLine";
import AlbumCredits from "modules/AlbumDetailPage/AlbumCredits";
import AlbumColour from "modules/AlbumDetailPage/AlbumColour";
import AlbumCategory from "modules/AlbumDetailPage/AlbumCategory";
import AlbumNext from "modules/AlbumDetailPage/AlbumNext";
import { media } from "utils/mediaQuery";

const AlbumLayout = styled(Layout)`

  background: ${props => props.isVideo ? 'rgb(var(--theme-black))' : 'rgb(var(--theme-background))'};
`

const AlbumCarousel = styled(SimpleCarouselThreeDee)` 
  
`

const Shelf = styled.div` 
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  flex-direction: column;

  ${media.small` 
    justify-content: center;
    align-items: center;
  `}
`

const Stage = styled.div` 
  position: relative;
  width: 100%;
  overflow: ${props => props.isVideo ? 'hidden' : 'visible'};
  height: ${props => props.isVideo ? '100%' : '70%'};
  display: ${props => props.isVideo ? 'flex' : 'block'};
  flex-direction: column;
  justify-content: flex-end;
  ${media.medium` 
    height: ${props => props.isVideo ? '100%' : '80%'};
    flex: ${props => props.isVideo ? 1 : 'none'};
  `}
`

const StageLine = styled.div` 
  position: relative;
  padding: 16px 0;

  ${media.small` 
    position: ${props => props.isAbsolute ? 'absolute' : 'relative'};
    left: 0;
    bottom: 0;
    width: 100%;
  `}
`


const AlbumPage = ({data:  { prismicAlbum, next }}) => {
  const [ MobileTopLinePos, setMobileTopLinePos ] = useState();
  const [ videoObject, setVideoObject ] = useState();
  const { width } = React.useContext(SiteContext);
  
  const {
    artist,
    project,
    year,
    carousel,
    audio,
    media_name,
    media_links,
    video,
    credits,
    types,
    formats,
  } = prismicAlbum.data;

  const [ ImageSet, setImageSet] = useState(null);

  useEffect(() => {
    if(video.url){
      document.body.setAttribute('data-theme', 'dark');
    }

    if(width && width >= 900) {
      setImageSet(carousel.filter((image,index) => index >= 1 ))
    } else if (width) {
      setImageSet(carousel);
    }

    return () => {
      document.body.setAttribute('data-theme', 'light');
    }
  }, [width])

  let textureImage;
  console.log(carousel.length)
  if(carousel.length > 0 && !video.url) {
    const imageurl = carousel[0].image?.gatsbyImageData?.images.fallback.src;
    const parsedPath = imageurl.substring(0,imageurl.indexOf("?"))
    const parsedImage = queryString.parse(carousel[0].image?.gatsbyImageData?.images.fallback.src);
    parsedImage.w = 900;
    parsedImage.h = 900;
    textureImage = `${parsedPath}?${queryString.stringify(parsedImage)}`;
  } else {
    textureImage = null;
  }

  const textureImageData = textureImage ? getImage(carousel[0].image) : null;
  const videoUrl = video.url;
  const audioObject = audio.url ? {
    src: audio.url,
    title: media_name
  }: null;

  return (
    <AlbumLayout omitCopy omitFilter navposition='fixed' isVideo={video.url ? true : false}>
      <Seo title={`${project} by ${artist}`} />
      <Shelf>
        <Stage isVideo={videoUrl}>
          <MobileAlbumTopLine artist={artist} title={project} year={year} pos={MobileTopLinePos} isVideo={videoUrl}/>
          { textureImage && ImageSet ? <AlbumCarousel texture={textureImage} textureImageData={textureImageData} alt={carousel[0] ? carousel[0].image : null} titlePosCallback={(pos)=>setMobileTopLinePos(pos)}>
            { ImageSet && ImageSet.length > 0 ? ImageSet.map((image,i)=>{
              const imageData = getImage(image.image);
              return <CarouselImage key={i} image={imageData} alt={image.image.alt}/>
            }) : null }
          </AlbumCarousel> : videoUrl ? <Megatron src={video.url} title={project} getObject={(object)=>{setVideoObject(object)}}/> : null}
        </Stage>
        <StageLine isAbsolute={textureImage}>
          <AlbumTopLine artist={artist} title={project} year={year} audio={audioObject} videoObject={videoObject} media_links={media_links}/>
        </StageLine>
      </Shelf>
      <AlbumCredits data={credits}/>
      { textureImage ? <AlbumColour image={textureImage}/> : null }
      <AlbumCategory types={types} formats={formats}/>
      <AlbumNext data={next.edges}/>
    </AlbumLayout>
  )
}

export default AlbumPage

export const query = graphql`
  query($id: String, $n_uid: [String!]!) {
    prismicAlbum(id: { eq: $id }) {
      uid
      data {
        artist
        project
        carousel {
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, sizes: "50vw")
            alt
          }
        }
        video {
          url
        }
        year
        media_name
        media_links {
          source
          link {
            url
          }
        }
        types {
          type {
            document {
              ... on PrismicTypeTag {
                uid
                data {
                  label
                }
              }
            }
          }
        }
        formats {
          format {
            document {
              ... on PrismicFormatTag {
                data {
                  label
                }
                uid
              }
            }
          }
        }
        audio {
          url
        }
        credits {
          name
          role
          link {
            url
          }
        }
      }
    }
    next: allPrismicAlbum(filter: {id: { in: $n_uid }}, sort: {order: ASC, fields: first_publication_date}) {
      edges {
        node {
          uid
          data {
            artist
            project
            carousel {
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, sizes: "(min-width: 0px) 300px, (min-width: 768px) 500px, (min-width: 1080px) 800px, (min-width: 1920px) 1000px")
              }
            }
            video_thumb {
              alt
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, sizes: "(min-width: 0px) 300px, (min-width: 768px) 500px, (min-width: 1080px) 800px, (min-width: 1920px) 1000px")
            }
          }
        }
      }
    }
  }
`